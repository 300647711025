import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DomPurify from '@core/helpers/dom-purify';
import Footer from '@core/layout/footer/footer';

import HeaderCustomerSuccess from '@modules/customer_success/header-customer-success';

import CollageParagraph from '@shared/collage/collage';
import { Graphql } from '@models/graphql';

export default function CustomerSuccessPage(): ReactElement {
  const { t } = useTranslation();

  const collaseData = {
    title: '',
    subtitle: '',
    relationships: {
      images: [
        {
          name: 'CS-1.png',
          attributes: {
            alt: 'T1',
            title: 'T1'
          }
        },
        {
          name: 'CS-2.png',
          attributes: {
            alt: 'T2',
            title: 'T2'
          }
        },
        {
          name: 'CS-3.png',
          attributes: {
            alt: 'T3',
            title: 'T3'
          }
        },
        {
          name: 'CS-4.png',
          attributes: {
            alt: 'T4',
            title: 'T4'
          }
        },
        {
          name: 'CS-5.png',
          attributes: {
            alt: 'T5',
            title: 'T5'
          }
        },
        {
          name: 'CS-6.png',
          attributes: {
            alt: 'T6',
            title: 'T6'
          }
        }
      ]
    }
  } as Graphql;

  return (
    <>
      <HeaderCustomerSuccess />
      <div className="container">
        <div className="text-center col-sm-12 offset-sm-0 col-md-8 offset-md-2 my-4">
          <h4 className="text-bluish">{t('Customer success')}</h4>
          <h4 className="text-dark-indigo px-md-1 px-lg-5 mx-md-1 mx-lg-5">{t('Customer success subtitle')}</h4>
          <p className="font-weight-normal text-secondary">{t('Customer success description')}</p>
          <GatsbyLink to={t('Path customer success form')} className="text-white btn shadow-none text-uppercase background-tangerine mt-4">
            {t('Customer success start')}
          </GatsbyLink>
        </div>
        <div className="my-5">
          <CollageParagraph node={collaseData} />
        </div>
        <div className="wysiwyg mb-5">
          <DomPurify text={t('Customer success Content')} />
        </div>
      </div>
      <Footer />
    </>
  );
}
