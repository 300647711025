import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import ImageProvider from '@core/providers/image/image-provider';

export default function HeaderCustomerSuccess(): ReactElement {
  const { i18n } = useTranslation();

  return (
    <nav className="navigation-bar sticky-top p-3 navbar navbar-expand-lg navbar-light shadow-sm">
      <div className="container">
        <GatsbyLink className="navbar-brand" to={`/${i18n.language}`}>
          <ImageProvider
            fileName="logo-rootstack.png"
            width="180"
            className="d-inline-block align-top"
            lazy={false}
          />
        </GatsbyLink>
      </div>
    </nav>
  );
}
